<!--
 * @Author: your name
 * @Date: 2022-03-29 11:39:57
 * @LastEditTime: 2022-03-29 13:00:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vi\src\views\Third\index.vue
-->
<template>
  <div></div>
</template>
<script>

import { THIRD_LOGIN } from '@/api/comments.js'
export default {
    data() {
        return {
            key:'',
            msg:''
        }
    },
    onload(e) {
        // ;
    },
    mounted() {
        // 
        this.third(this.$route.query.key)
    },
    methods:{
        third(code){
            THIRD_LOGIN({
                key : code
            }).then(
                (res)=>{
                    
                    if (res.data.ret === 1) {
                      this.$store.commit('setUser', res.data.data.token)
                      this.$toast.success('đăng nhập thành công')
                      localStorage.removeItem('fromid')
                      this.$router.push('/')
                    } else {
                      this.$toast.fail(res.data.msg)
                    }
                  }
                )
              }
    }
}
</script>

<style scoped></style>
